<template>
  <v-card elevation="0" outlined class="fliter-wrap">
    <div class="filter-details">
      <div class="d-flex filter-sec">
        <div class="d-flex">
          <v-icon class="mr-2">mdi-filter-variant</v-icon>
          <h4 class="name">Filter Results</h4>
        </div>
        <v-chip class="reset" @click.prevent="resetFilters">
          Reset filters
        </v-chip>
      </div>
      <div class="header">Fixture Date</div>
      <div class="date d-flex align-center justify-center">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateUkFormat(filterPayload.fixture_start_date)"
              hide-details="auto"
              solo
              class="date-input"
              v-on="on"
              v-bind="attrs"
              placeholder="Date from"
            >
              <template slot="prepend-inner">
                <img class="calendar" src="@/assets/images/calendar.svg" />
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filterPayload.fixture_start_date"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn :ripple="false" text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn
              :ripple="false"
              text
              color="primary"
              @click="$refs.menu.save(filterPayload.fixture_start_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateUkFormat(filterPayload.fixture_end_date)"
              hide-details="auto"
              solo
              class="date-input"
              v-bind="attrs"
              v-on="on"
              placeholder="Date to"
            >
              <template slot="prepend-inner">
                <img class="calendar" src="@/assets/images/calendar.svg" />
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filterPayload.fixture_end_date"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn :ripple="false" text color="primary" @click="menu2 = false">
              Cancel
            </v-btn>
            <v-btn
              text
              :ripple="false"
              color="primary"
              @click="$refs.menu2.save(filterPayload.fixture_end_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>
    <v-divider class="line"></v-divider>
    <v-expansion-panels class="expansion" flat v-model="filterPanel" multiple>
      <template v-for="(item, key, index) in fixturesFilters">
        <v-expansion-panel
          v-if="
            key !== type &&
            key !== 'home_match' &&
            key !== 'away_match' &&
            Object.keys(fixturesFilters[key]).length
          "
          expand
          class="filter-panel"
          :key="index"
        >
          <v-expansion-panel-header class="header">
            {{ key | capitalize }}
            <template v-slot:actions>
              <v-icon v-if="filterPanel.indexOf(index) > -1" color="#070B32">
                mdi-minus
              </v-icon>
              <v-icon v-if="filterPanel.indexOf(index) < 0" color="#070B32">
                mdi-plus
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-content">
            <div
              class="filter-checkbox d-flex align-center justify-space-between"
              v-for="(list, ind) in fixturesFilters[key]"
              :key="ind + key"
            >
              <div class="d-flex align-center">
                <v-checkbox
                  :color="true ? '#070B32' : '#D3D5D7'"
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  :ripple="false"
                  @change="setFilters(key, list.id)"
                ></v-checkbox>
                <span>{{ list.name | capitalize }}</span>
              </div>
              <div class="count" v-if="list.total">({{ list.total }})</div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="type === 'team' && key === 'home_match'"
          expand
          class="filter-panel"
          :key="index + key"
        >
          <v-expansion-panel-header class="header">
            Home or Away
            <template v-slot:actions>
              <v-icon v-if="filterPanel.indexOf(index) > -1" color="#070B32">
                mdi-minus
              </v-icon>
              <v-icon v-if="filterPanel.indexOf(index) < 0" color="#070B32">
                mdi-plus
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="filter-content">
            <div
              class="filter-checkbox d-flex align-center justify-space-between"
            >
              <div class="d-flex align-center">
                <v-checkbox
                  v-model="filterPayload.home_match"
                  :color="true ? '#070B32' : '#D3D5D7'"
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  :ripple="false"
                ></v-checkbox>
                <span> Home Matches </span>
              </div>
              <div class="count">({{ fixturesFilters["home_match"] }})</div>
            </div>
            <div
              class="filter-checkbox d-flex align-center justify-space-between"
            >
              <div class="d-flex align-center">
                <v-checkbox
                  v-model="filterPayload.away_match"
                  :color="true ? '#070B32' : '#D3D5D7'"
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  :ripple="false"
                ></v-checkbox>
                <span> Away Matches </span>
              </div>
              <div class="count">({{ fixturesFilters["away_match"] }})</div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import DateFormat from "@/mixins/Dates/ukFormat.js";
export default {
  name: "FilterResults",
  mixins: [DateFormat],
  data: () => ({
    menu: false,
    menu2: false,
    navigationTab: null,
    page: 1,
    dateFrom: "",
    dateTo: "",
    filterPanel: [0, 1],
    filterPayload: {
      page: 1,
      per_page: 15,
      tournament_id: [],
      team_id: [],
      stadium_id: [],
      home_match: "",
      away_match: "",
      start_date: "",
      end_date: "",
    },
    filterResultsPayload: {
      getters: ["team", "tournament", "home_match", "away_match"],
      filters: {
        tournament_id: [],
        team_id: [],
        stadium_id: [],
        popular: 0,
      },
    },
  }),
  props: {
    type: {
      type: String,
      default() {
        return "team";
      },
    },
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  watch: {
    id: {
      handler: function () {
        if (this.type === "tournament") {
          this.filterPayload.tournament_id = [
            this.$store.state.tournaments.details.id,
          ];
          this.filterResultsPayload.filters.tournament_id = [
            this.$store.state.tournaments.details.id,
          ];
        }
        if (this.type === "stadium") {
          this.filterPayload.stadium_id = [
            this.$store.state.stadiums.details.id,
          ];
          this.filterResultsPayload.filters.stadium_id = [
            this.$store.state.stadiums.details.id,
          ];
        }
        if (this.type === "popular") {
          this.filterPayload.popular =
            this.filterResultsPayload.filters.popular = this.id;
        }
        this.loadFiltersDetails();
      },
      deep: true,
      immediate: true,
    },
    filterPayload: {
      handler: function () {
        this.loadFixtures();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.loadFiltersDetails();
    this.$root.$on("GetUpcomingFixturesByFiters", (data) => {
      this.filterPayload.page = data;
    });
  },
  computed: {
    fixturesFilters() {
      return this.$store.state.fixtures.fixturesFilters;
    },
  },
  methods: {
    async loadFixtures() {
      await this.$store.dispatch("fixtures/GET_FIXTURES_BY_FILTER", {
        filters: this.filterPayload,
      });
    },
    async loadFiltersDetails() {
      await this.$store.dispatch(
        "fixtures/GET_FIXTURES_FILTERS",
        this.filterResultsPayload
      );
    },
    async resetFilters() {
      this.filterPayload = {
        page: 1,
        per_page: 15,
        tournament_id: [],
        team_id: [],
        stadium_id: [],
        home_match: "",
        away_match: "",
        start_date: "",
        end_date: "",
        popular: 0,
      };
      await this.loadFixtures();
    },
    setFilters(key, id) {
      debugger;
      switch (key) {
        case "team":
          this.setTeamFilterPayload(key, id);
          break;
        case "tournament":
          this.setTournamentFilterPayload(key, id);
          break;
        case "stadium":
          this.setStadiumFilterPayload(key, id);
          break;
        case "popular":
          this.filterPayload.popular = id;
          break;
        default:
          this.setTeamFilterPayload(key, id);
      }
    },
    setTournamentFilterPayload(key, id) {
      const index = this.filterPayload.tournament_id.indexOf(id);
      index >= 0
        ? this.filterPayload.tournament_id.splice(index, 1)
        : this.filterPayload.tournament_id.push(id);
    },
    setTeamFilterPayload(key, id) {
      const index = this.filterPayload.team_id.indexOf(id);
      index >= 0
        ? this.filterPayload.team_id.splice(index, 1)
        : this.filterPayload.team_id.push(id);
    },
    setStadiumFilterPayload(key, id) {
      const index = this.filterPayload.stadium_id.indexOf(id);
      index >= 0
        ? this.filterPayload.stadium_id.splice(index, 1)
        : this.filterPayload.stadium_id.push(id);
    },
  },
};
</script>
<style></style>
