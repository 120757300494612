<template>
  <v-card elevation="0" class="sellticket">
    <div class="d-flex px-0 align-center tag-sec">
      <img
        class="tagpin mr-2"
        src="@/assets/images/tagpin.png"
        alt="Do you have a ticket to sell?"
      />
      <div class="tag-title">Do you have a ticket to sell?</div>
    </div>
    <div>
      <p class="card-info mb-0">
        Want to list your tickets on our site? Click through to find out more
      </p>
    </div>
    <div class="ticket">
      <v-btn
        to="/sell-your-tickets"
        :ripple="false"
        outlined
        class="btn-sellticket"
      >
        Sell Your Tickets
        <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg"
      /></v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "SellYourTicketCard",
};
</script>

<style></style>
