<template>
  <div class="about-matches upcoming-events">
    <h3 class="name">{{ title }}</h3>
    <template v-if="loading">
      <v-skeleton-loader
        v-for="item in 8"
        :key="item / 4"
        class="mx-auto"
        type="list-item-avatar-three-line, divider"
      ></v-skeleton-loader>
    </template>
    <template v-if="fixtures.length">
      <event-card
        class="mb-2"
        v-for="(item, index) in fixtures"
        :key="`${item.id}'-'${index}`"
        :event-info="item"
      />
      <div class="text-center">
        <v-pagination
          class="upcoming-pagination"
          v-model="page"
          :length="pagination.last_page"
          @input="paginate"
        ></v-pagination>
      </div>
    </template>
    <template v-if="!loading && fixtures.length === 0">
      <no-records-found type="search" />
    </template>
  </div>
</template>

<script>
import EventCard from "@/components/EventMolecules/EventCard.vue";
import NoRecordsFound from "@/components/NoRecordsFound.vue";
export default {
  name: "UpcomingFixturesByFilter",
  components: { NoRecordsFound, EventCard },
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    page: 1,
  }),
  watch: {
    page: {
      handler: function () {
        this.$root.$emit("GetUpcomingFixturesByFiters", this.page);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    fixtures() {
      return this.$store.state.fixtures.fixturesUpcoming;
    },
    pagination() {
      return this.$store.state.fixtures.fixturesUpcomingPagination;
    },
  },
  methods: {
    paginate(val) {
      this.page = val;
    },
  },
};
</script>
<style></style>
