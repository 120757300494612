<template>
  <div
    class="px-63 py-43 bg-color-grey-100 d-flex align-center justify-space-between mb-8"
  >
    <img
      height="50"
      width="100"
      class="mr-5"
      src="@/assets/images/order-not-confirm.svg"
    />
    <p
      class="font-18 line-h-25 font-weight--bold text-color-secondary-100 letter-s-25 mb-0"
    >
      We’re sorry, there’s currently no tickets available for this {{ type }}.
      Please check back soon or
      <a
        href=""
        class="text-decoration-underline font-weight--extra-bold text-color-secondary-300"
      >
        contact us
      </a>
      to find out when they will be available.
    </p>
  </div>
</template>

<script>
export default {
  name: "NoRecordsFound",
  props: {
    type: String,
    default() {
      return "";
    },
  },
};
</script>

<style></style>
